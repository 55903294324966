import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-syracuse-new-york.png'
import image0 from "../../images/cities/scale-model-of-armory-square-in-syracuse-new-york.png"
import image1 from "../../images/cities/scale-model-of-erie-canal-museum-in-syracuse-new-york.png"
import image2 from "../../images/cities/scale-model-of-onondaga-historical-association-in-syracuse-new-york.png"
import image3 from "../../images/cities/scale-model-of-everson-museum-of-art-in-syracuse-new-york.png"
import image4 from "../../images/cities/scale-model-of-museum-of-science-&-technology-in-syracuse-new-york.png"
import image5 from "../../images/cities/scale-model-of-ski-areas-of-new-york-inc---i-ski-ny-in-syracuse-new-york.png"
import image6 from "../../images/cities/scale-model-of-visit-syracuse-in-syracuse-new-york.png"
import image7 from "../../images/cities/scale-model-of-advantage-travel-of-cny-inc-in-syracuse-new-york.png"
import image8 from "../../images/cities/scale-model-of-bti-the-travel-consultants-in-syracuse-new-york.png"
import image9 from "../../images/cities/scale-model-of-burns-garfield-funeral-home-in-syracuse-new-york.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Syracuse'
            state='New York'
            image={image}
            lat='43.041'
            lon='-76.1436'
            attractions={ [{"name": "Armory Square", "vicinity": "Syracuse", "types": ["point_of_interest", "establishment"], "lat": 43.0485152, "lng": -76.15523300000001}, {"name": "Erie Canal Museum", "vicinity": "318 Erie Blvd E, Syracuse", "types": ["museum", "library", "point_of_interest", "establishment"], "lat": 43.050679, "lng": -76.14882899999998}, {"name": "Onondaga Historical Association", "vicinity": "321 Montgomery St, Syracuse", "types": ["museum", "store", "point_of_interest", "establishment"], "lat": 43.047884, "lng": -76.149317}, {"name": "Everson Museum of Art", "vicinity": "401 Harrison St, Syracuse", "types": ["museum", "point_of_interest", "establishment"], "lat": 43.0447232, "lng": -76.14674209999998}, {"name": "Museum of Science & Technology", "vicinity": "500 S Franklin St, Syracuse", "types": ["museum", "movie_theater", "point_of_interest", "establishment"], "lat": 43.04709829999999, "lng": -76.15559080000003}, {"name": "Ski Areas of New York Inc - I SKI NY", "vicinity": "4583 North St Suite #1, Jamesville", "types": ["point_of_interest", "establishment"], "lat": 42.99362900000001, "lng": -76.070941}, {"name": "Visit Syracuse", "vicinity": "115 W Fayette St, Syracuse", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 43.048467, "lng": -76.15303799999998}, {"name": "Advantage Travel of CNY Inc", "vicinity": "313 E Willow St # 104, Syracuse", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 43.0532449, "lng": -76.1494912}, {"name": "BTI The Travel Consultants", "vicinity": "124 E Jefferson St, Syracuse", "types": ["travel_agency", "real_estate_agency", "point_of_interest", "establishment"], "lat": 43.0469583, "lng": -76.15150799999998}, {"name": "Burns Garfield Funeral Home", "vicinity": "3175 E Genesee St, Syracuse", "types": ["funeral_home", "park", "point_of_interest", "establishment"], "lat": 43.04327139999999, "lng": -76.0966798}] }
            attractionImages={ {"Armory Square":image0,"Erie Canal Museum":image1,"Onondaga Historical Association":image2,"Everson Museum of Art":image3,"Museum of Science & Technology":image4,"Ski Areas of New York Inc - I SKI NY":image5,"Visit Syracuse":image6,"Advantage Travel of CNY Inc":image7,"BTI The Travel Consultants":image8,"Burns Garfield Funeral Home":image9,} }
       />);
  }
}